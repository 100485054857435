<template>
  <div class="centerbox">
    <div class="addtxt">新闻添加</div>
    <div style="margin-left: 5%">
      <el-form ref="form" label-width="80px">
        <el-form-item label="文章标题">
          <el-input v-model="title" class="titleinput"></el-input>
          <span class="must">必填</span>
        </el-form-item>
        <el-form-item label="文章类型">
          <span class="must">
            <el-radio v-model="radio" label="1">普通</el-radio>
            <el-radio v-model="radio" label="2">视频</el-radio>
          </span>
        </el-form-item>
        <el-form-item label="所属栏目">
          <!-- <el-select
            v-model="region"
            placeholder="请选择所属栏目"
            class="columnselect"
          >
            <el-option label="工会之家" value="shanghai"></el-option>
          </el-select> -->
          <el-select
            v-model="region"
            @change="regionChange()"
          >
            <el-option
              v-for="val in regionlist"
              :key="val.cid"
              :value="val.cid"
              :label="val.cname"
            />
          </el-select>

          <span class="must">必填</span>
        </el-form-item>

        <el-form-item label="缩略图">
          <Upload
            name="img"
            :on-success="successImg"
            action="https://api.hxsh.ikoo.store/api/Common/UpLoadImage"
          >
            <Button
              style="border-color: #53cac3; color: #7f7f7f"
              class="title-btn fourSize"
              >上传图片</Button
            >
          </Upload>
          <img
            class="nicimg"
            :src="'http://img.hxsh.ikoo.store/' + zynimg"
            v-if="ifshow"
          />
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="weight" class="currencyinput"></el-input>
          <span class="must">必填</span>
        </el-form-item>

        <el-form-item label="阅读基数">
          <el-input v-model="base" class="currencyinput"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="作者">
          <el-input v-model="author" class="currencyinput"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="来源">
          <el-input v-model="source" class="currencyinput"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="关键字">
          <el-input v-model="keyword" class="currencyinput"></el-input>
          <span class="wrongmust">选填,多个用“,”号隔开</span>
        </el-form-item>

        <el-form-item label="原文链接">
          <el-input v-model="link" class="titleinput"></el-input>
          <span class="wrongmust">选填</span>
        </el-form-item>

        <el-form-item label="二维码">
          <Upload
            name="img"
            :on-success="successImg2"
            action="https://api.hxsh.ikoo.store/api/Common/UpLoadImage"
          >
            <Button
              style="border-color: #53cac3; color: #7f7f7f"
              class="title-btn fourSize"
              >上传图片</Button
            >
          </Upload>
          <!-- <img :src="'https://img.hxsh.ikoo.store/' + zynimg2" /> -->
        </el-form-item>

        <el-form-item label="文章摘要">
          <el-input
            type="textarea"
            v-model="abstract"
            class="textinput"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章内容">
          <!-- <div>
            <editor-bar
              v-model="value11"
              :isClear="isClear"
              @change="changes"
            ></editor-bar>
          </div> -->
          <!-- <div style="border: 1px solid #ccc; width: 85%">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div> -->
          <div>
            <el-form ref="form" v-model="editorContent">
              <el-form-item class="content" v-model="editorContent">
                <div
                  style="border: 1px solid #ccc; width: 85%"
                  id="editor"
                  ref="editor"
                  :v-model="html2"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import EditorBar from "../../components/editor.vue";
import E from "wangeditor";
export default {
  components: { Editor, Toolbar, EditorBar },
  data() {
    return {
      isClear: false,
      value11: "",
      editor: null,
      html: "<p>输入正文内容</p>",
      html2: "<p>输入正文内容</p>",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: { customUpload: this.uploadImg },
      },
      mode: "default", // or 'simple'
      title: "",
      region: "",
      regionlist: "",
      weight: 0, //权重
      base: "", //阅读基数
      author: "", //作者
      source: "", //来源
      keyword: "", //关键字
      link: "", //原文链接
      abstract: "", //文章摘要
      content: "", // 文章内容
      delivery: false,
      type: [],
      desc: "",
      radio: "1",
      fileList: [],
      imgsrc: "",
      imageUrl: "",
      zynimg: "",
      zynimg2: "",
      ifshow: false,
      baseUrl: "https://api.hxsh.ikoo.store/api/Common/UpLoadImage",
      editorContent: "",
      form: {},
    };
  },
  watch: {
    // content() {
    //   this.editor.txt.html(this.editorContent);
    // },
  },
  mounted() {
    this.wangeditor();
    this.getclassid();
    // this.toolbarConfig.excludeKeys = [
    //   "group-video", // 排除菜单组，写菜单组 key 的值即可
    // ];
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>输入正文内容</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    changes(val) {
      console.log(val);
    },
    wangeditor() {
      const editor = new E(this.$refs.editor);
      // 删除上传视频功能
      editor.config.menus.splice(18, 1);
      // 上传图片
      editor.config.uploadImgShowBase64 = false; //如果为true，则不用配置下面的
      editor.config.uploadImgServer = this.baseUrl; // 这是服务器端上传图片的接口路径
      editor.config.uploadFileName = "img"; // 后端接受上传文件的参数名
      // editor.config.uploadImgHeaders = { 'Authorization': 'Bearer ' + getToken() }// 设置请求头
      editor.config.uploadImgHooks = {
        customInsert: function (insertImg, result, editor) {
          var url = "http://img.hxsh.ikoo.store/" + result.filename; //当前result.data.filePath为后端返回的半截路径，如果为全路径 则不用拼
          insertImg(url);
        },
      };
      // 上传图片大小 5M
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      // // 隐藏插入网络图片的功能
      editor.config.showLinkImg = false;
      // // 改变内容触发回调函数
      editor.config.onchange = this.editorCallBack;
      // // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      // 回显已有内容
      editor.txt.html(this.editorContent);
      editor.config.onchange = (html) => {
        this.html2 = html; // 绑定当前逐渐地值 // 将内容同步到父组件中
      };
    },
    logo() {
      console.log(this.html2);
    },
    successImg(response, file, fileList) {
      // 上传图片成功后回调
      // console.log(response);
      if (response.result) {
        this.ifshow = true;
        this.zynimg = response.filename;
      }
    },
    successImg2(response, file, fileList) {
      // 上传图片成功后回调
      if (result) {
        this.zynimg2 = response.filename;
      }
    },
    regionChange() {
      // console.log("选中id:", this.region);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = res[0].newFileName;
      console.log(this.imageUrl);
    },
    getclassid() {
      this.$request({
        url: "/api/News/M_ClassList",
        method: "GET",
        params: {},
      })
        .then((res) => {
          if (res.data.status) {
            this.regionlist = res.data.list;
          }
        })
        .finally(() => {});
    },
    onSubmit() {
      if (this.title == "") {
        this.$message.error("文章标题不能为空");
      }
      if (this.region == "") {
        this.$message.error("所属栏目不能为空");
      }
      if (this.title !== "" && this.region !== "" && this.weight !== "") {
        this.$request({
          url: "/api/News/M_NewsAdd",
          method: "POST",
          data: {
            title: this.title,
            npic: this.zynimg,
            classid: this.region,
            read: 0,
            ntype: 0,
            sort: this.weight,
            content: this.html2,
            fromurl: this.link,
            descrip: this.abstract,
          },
        })
          .then((res) => {
            if (res.data.status) {
              // console.log(res);
              this.$message({
                message: "提交成功",
                type: "success",
              });
              setTimeout((res) => {
                this.$router.push("/newslist");
              }, 1500);
            }
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  height: auto;
  color: #000;
  overflow: hidden;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
.el-input {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
</style>
<style scoped>
.centerbox {
  background-color: #fff;
}
.addtxt {
  padding: 20px 1%;
}
.must {
  color: red;
  margin-left: 10px;
}
.wrongmust {
  color: #ccc;
  margin-left: 10px;
}
.titleinput {
  width: 280px;
}
.columnselect {
  width: 160px;
}
.uploadbox {
  width: 300px;
}
.currencyinput {
  width: 160px;
}
.textinput {
  width: 280px;
}
.nicimg {
  width: 140px;
  height: 140px;
}
</style>
